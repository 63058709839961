import React from "react";
import Addonsdata from "../../data/addons.json";
import Tabdata from "./tabdata";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
const Addons = (props) => {
  return (
    <>
      <div className="mycartpopup">
        Make It Extra Special !{" "}
        <span className="closespan">
          <button onClick={props.handlerfal} className="closebutton">
            X
          </button>
        </span>
      </div>

      <div className="container" style={{ marginTop: "20px" }}>
        <Tabs>
          <TabList>
            {Addonsdata.map((item, i) => (
              <Tab key={i}>{item.Label}</Tab>
            ))}
          </TabList>
          {Addonsdata.map((item, index) => (
            <TabPanel>
              <div className="maxhightclass" style={{ position: "relative" }}>
                <div className="row g-3">
                  {item.products?.nodes.map((product, index) => (
                    <Tabdata product={product} key={index} />
                  ))}
                </div>
              </div>
            </TabPanel>
          ))}
        </Tabs>

        <button onClick={props.handlert} className="proceed_to_cart">Proceed To Cart</button>
      </div>
      {/* <button
            onClick={props.handlert}
          > Gocart </button>
           */}
          
    </>
  );
};
export default Addons;
