import React from 'react'
import * as types from '../../redux/ActionTypes';
import { useDispatch } from "react-redux";
import { ToastContainer } from 'react-toastify'
const CartHeader = ({ item }) => {
  const dispatch = useDispatch();
  return (
  <>
    
    <div className="product" style={{marginBottom:"10px"}}>
    <a href={null} className="removeitem" onClick={()=>dispatch({type: types.REMOVE_FROM_CART,product_Id: item})} ><i className="fa fa-times-circle" ></i></a>
    <div className="row " style={{ alignItems: "center" }}>
      <div className="col-md-4">
        <img
        className="img-fluid mx-auto d-block image"
        //   src={process.env.PUBLIC_URL + item.image[0]}
        src="/assets/img/product/hm9-cagi-3.jpg"
              alt="..."
              width="100%"
        />
      </div>
      <div className="col-md-8">
        <div className="info">
          <div className="row">
            <div className="col-md-8 product-name">
              <div className="product-name">
                <a href="#">{item.name} ({item.currencySymbol} {item.price})</a>
                <div className="product-info">
                  <div>
                    Delivery Option: <span className="value">5 inch</span>
                  </div>
                  <div>
                    Weight: <span className="value">4GB</span>
                  </div>
                  <div>
                    Flavour: <span className="value">32GB</span>
                  </div>
                  <div>
                    Delivery Date: <span className="value">32GB</span>
                  </div>
                  <div>
                    Delivery Time: <span className="value">32GB</span>
                  </div>
                </div>

                
              </div>
            </div>
            <div className="col-md-4 quantity">
              <span>{item.currencySymbol} {item.sum}</span>
              <input
                id="quantity"
                type="number"
                defaultValue={item.qty}
                className="form-control quantity-input"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
   
  </div>
 
  
  </>
  
  )}

export default CartHeader;