import { ADD_TO_CART, REMOVE_FROM_CART } from "../ActionTypes";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function cartReducer(state = { cart: [] }, action) {
  switch (action.type) {
    case ADD_TO_CART:
      const productId = action.product.id;
      if (state.cart.findIndex((product) => product.id === productId) !== -1) {
        const cart = state.cart.reduce((cartAcc, product) => {
          if (product.id === productId) {
            cartAcc.push({
              ...product,
              qty: product.qty + 1,
              sum: product.price * (product.qty + 1),
            });
          } else {
            cartAcc.push(product);
          }
          return cartAcc;
        }, []);

        return { ...state, cart };
      }

      return {
        ...state,
        cart: [
          ...state.cart,
          {
            ...action.product,
            qty: action.qty,
            sum: action.product.price * action.qty,
          },
        ],
      };

    case REMOVE_FROM_CART:
      toast.success("Item Removed from Cart !!");   
      return {
        cart: state.cart.filter((item) => item.id !== action.product_Id.id),
      };
    default: {
    }
  }
  return state;
}
