import React, { useState } from "react";
import SlidingPanel from "react-sliding-side-panel";
import CartHeader from '../../components/cart/cart-header'
import Addons from '../../components/addons/addons'
import { useSelector } from "react-redux";

const Footer = () => {
  const [state, setState] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false,
  });

  const cartList = useSelector((state) => state.cartList.cart);
  const handlerfalse=()=> {
    setState({ isPaneOpen: false })
  }

  const handlertrue=()=> {
    setState({ isPaneOpenLeft: true, isPaneOpen: false })
  }
  
  return (
    <>
      <button
        className="whatsapp_float"
        onClick={() => setState({ isPaneOpen: true })}
      >
        {" "}
        <i className="fas fa-shopping-cart"></i>
      </button>

      <SlidingPanel type={"right"} isOpen={state.isPaneOpen} size={37}>
         <Addons handlerfal = {handlerfalse} handlert={handlertrue}/>
      </SlidingPanel>

      <SlidingPanel type={"right"} isOpen={state.isPaneOpenLeft} size={37}>
        <div className="mycartpopup">My Cart ({cartList.length}) <span className="closespan"><button onClick={() => setState({ isPaneOpen: false })} className="closebutton">X</button></span></div>
        <div className="container" style={{maxHeight: "620px", overflow: "auto",marginTop:"20px"}}>
          <div className="items">
          {cartList.map((item, index) => (
                <CartHeader key={index}
                    item={item}
                    // total={total}
                    
                />
            
           
           ))}

          </div>
          <button>Proceed To checkout</button>
        </div>
      </SlidingPanel>
    </>
  );
};
export default Footer;
