import React, { useState } from "react";
import { useVariations } from "../../hooks/hooks";
import Select from 'react-select';
const Selectoption = (props) => { 
   const [selects, state, setState] = useVariations(props.product);
   function onChange(select, value) {
    const newState = {
      ...state,
      [select]: { ...value, name: select },
    };

    if (!value) {
      delete newState[select];
    }

    setState(newState);
  }
  console.log(state);
   return (
    <div className="row">
    {Object.keys(selects).map((select) => (
     <div className="col-md-6 col-sm-6">   
     <label
        style={{ fontSize: "14px" }}
        htmlFor="browser"
        className="form-label"
        >{selects[select].placeholder}</label>
      <Select
        isClearable
        key={selects[select].placeholder}
        placeholder={selects[select].placeholder}
        // value={state[select]}
        onChange={(o) => onChange(select, o)}
        options={selects[select].options}
      />
      </div>
    ))}
  </div>
   );
}
export default Selectoption;