import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Rating from "./ProductRating";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import Selectoption from "../../components/selectoption/selectoption";
import { addToCart } from "../../redux/actions/index";

const ProductGridListSingle = (props) => {
  const { product } = props;
  const dispatch = useDispatch();
  const getMoreTextDiv = (myText) => {
    return myText.substr(0, 70);
  };
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  //console.log(product.variations)

  return (
    <>
      <div className="col-sm-6 col-lg-3 mb-2-6">
        <div className="card-wrapper mb-4">
          <div className="card-img">
            <img
              src={process.env.PUBLIC_URL + product.image[0]}
              alt="..."
              width="100%"
            />
          </div>
          <div className="card-body">
            <div>
              <a>
                <i className="bg-white p-3 rounded-circle ti-shopping-cart font-weight-600" />
              </a>
            </div>
          </div>
        </div>
        <div className="text-center">
          <h4 className="h5 mb-2">
            <a className="text-secondary">{product.name}</a>
          </h4>
          <div className="ast-woo-shop-product-description">
            <p>{getMoreTextDiv(product.shortDescription)}...</p>
          </div>
          <div className="product-rating mb-2">
            {product.rating && product.rating > 0 ? (
              <Rating ratingValue={product.rating} />
            ) : (
              ""
            )}
          </div>
          <h5 className="mb-0 text-primary">
            {product.currencySymbol} {product.price}
          </h5>
          <div>
            {product.variations ? (
              <>
                <button
                  type="button"
                  onClick={onOpenModal}
                  className="btn btn-default btn-sm"
                >
                  Select Option
                </button>

                <Modal open={open} onClose={onCloseModal} center>
                  {product.name} (Product Name) {product.currencySymbol}{" "}
                  {product.price}
                  <div className="row" style={{ marginTop: "24px" }}>
                    <Selectoption product={product} />

                    {/* <div className="col-md-6 col-sm-6">
                      <label
                        style={{ fontSize: "14px" }}
                        htmlFor="browser"
                        className="form-label"
                      >
                        Delivery Location
                      </label>
                      <select name="" className="form-select">
                        <option value="delhi">Delhi</option>
                        <option value="faridabad">Faridabad</option>
                        <option value="ghaziabad">Ghaziabad</option>
                      </select>
                    </div>
                    <div className="col-md-6 col-sm-6 mt-1">
                      <label
                        style={{ fontSize: "14px" }}
                        htmlFor="browser"
                        className="form-label"
                      >
                        Delivery Options
                      </label>
                      <select className="form-select">
                        <option value="standard-delivery" data-price="0">Standard Delivery</option>
                        <option value="midnight-delivery-200" data-price="200">Midnight Delivery (+ ₹200 11:00PM-11:30PM)</option>
                      
                      </select>
                    </div>
                  </div>
                  <div className="row mt-1">
                    <div className="col-md-6  col-sm-6">
                      <label
                        style={{ fontSize: "14px" }}
                        htmlFor="browser"
                        className="form-label"
                      >
                        Flavour
                      </label>
                      <select className="form-select">
                        <option value="butter-scotch">Butter Scotch</option>
                        
                      </select>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <label
                        style={{ fontSize: "14px" }}
                        htmlFor="browser"
                        className="form-label"
                      >
                        Weight
                      </label>
                      <select className="form-select">
                        <option value="1kg">1kg</option>
                        <option value="1/2kg">1/2-kg</option>
                        <option value="2kg">2kg</option>
                     
                      </select>
                    </div> */}
                    <div className="row">
                      <div className="form-group mt-2 col-md-6 col-sm-6">
                        <label htmlFor="birthday">Delivery Date:</label>
                        <input
                          type="date"
                          className="form-control"
                          id="birthday"
                          name="birthday"
                        />
                      </div>
                      <div className="form-group mt-2 col-md-6  col-sm-6">
                        <label htmlFor="birthday">Delivery Time:</label>
                        <input
                          type="time"
                          className="form-control"
                          id="birthday"
                          name="birthday"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      type="button"
                      onClick={() => alert("hello")}
                      addToCart
                      className="btn btn-default btn-sm"
                    >
                      <span>
                        <i className="fas fa-shopping-cart"></i>
                      </span>
                      <b> Add to Cart </b>
                    </button>
                  </div>
                </Modal>
              </>
            ) : product.stock && product.stock > 0 ? (
              <>
                <button
                  type="button"
                  onClick={() => dispatch(addToCart(product, 1))}
                  className="btn btn-default btn-sm"
                >
                  <span>
                    <i className="fas fa-shopping-cart"></i>
                  </span>
                  <b> Add to Cart </b>
                </button>
              </>
            ) : (
              <button
                disabled
                className="active btn btn-default btn-sm"
                type="button"
              >
                Out of Stock
              </button>
            )}
          </div>
        </div>
        
       
      </div>
      
    </>
  );
};
export default ProductGridListSingle;
