export function filterVariations(variations, state) {
    if (Object.keys(state).length === 0) {
      return variations;
    }
  
    return Object.keys(state)?.reduce((accStateVars, currStateVar) => {
      return accStateVars.reduce((accVars, currVar) => {
        const filteredAttrsByName = currVar?.attributes?.nodes?.filter(
          (attr) => currStateVar === attr?.name,
        );
  
        const withSelected = currVar?.attributes?.nodes?.findIndex(
          (attr) => attr?.attributeId === state?.[currStateVar]?.value,
        );
  
        return [
          ...accVars,
          {
            attributes: {
              nodes:
                withSelected >= 0
                  ? currVar?.attributes?.nodes
                  : filteredAttrsByName,
            },
          },
        ];
      }, []);
    }, variations);
  }
  
  export function formatVariations(filteredVariations, variations) {
    const defaultSelects = variations?.reduce(
      (accVars, currVar) => ({
        ...accVars,
        ...currVar?.attributes?.nodes?.reduce(
          (accAttrs, currAttr) => ({ ...accAttrs, [currAttr.name]: {} }),
          {},
        ),
      }),
      {},
    );
  
    return filteredVariations.reduce((accVars, currVar) => {
      const filteredAttrs = currVar?.attributes?.nodes?.reduce(
        (accAttrs, currAttr) => {
          const exists =
            0 <=
            accVars[currAttr.name]?.options?.findIndex(
              (option) => option.value === currAttr.attributeId,
            );
          return {
            ...accAttrs,
            [currAttr.name]: {
              placeholder: currAttr.label,
              options: exists
                ? accVars[currAttr.name]?.options || []
                : [
                    ...(accVars[currAttr.name]?.options || []),
                    { label: currAttr.value, value: currAttr.attributeId },
                  ],
            },
          };
        },
        {},
      );
  
      return { ...accVars, ...filteredAttrs };
    }, defaultSelects);
  }