import React  from "react";
import products from "../../data/products.json";
import ProductGridListSingle from "./ProductGridListSingle";
import { connect } from "react-redux";
import { ToastContainer } from 'react-toastify'
const Home = (products) => {
  return (
    <>
      <section>
        <div className="container">
          <div className="row">
          {products.products.map((product) => (
             <ProductGridListSingle product={product} key={product.id} />

          ))}
         </div>
        </div>
        <ToastContainer position="bottom-left" />
      </section>
    </>
  );
};
const mapStateToProps = state => {
  
  return {
    products: state.productData.products
  };
};
export default connect(mapStateToProps)(Home);
