import * as types from '../ActionTypes';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



export const addToCart = (product, qty) => {
    console.log('hhhhhh');
    return (dispatch) => {
        toast.success("Item Added to Cart !!");
        dispatch(addtoCartData(product, qty));
    }
};


export const addtoCartData = (product, qty) => ({
    type: types.ADD_TO_CART,
    product,
    qty
});


// export const removeFromCart = productId => (dispatch) =>{
//         console.log(productId);
    
//         toast.success("Item Removed from Cart !!");
//         console.log('hello');
//         dispatch({
//             type: types.REMOVE_FROM_CART,
//             product_Id: productId
//         });
    
// };

// export const removeFromCart = (productId) => {
//     console.log(productId);
   
        
        
   
// } 