import React from "react";
import { useDispatch } from "react-redux";
import { addToCart } from "../../redux/actions/index";
const Tabdata = (props) => {
    const { product } = props;
    const dispatch = useDispatch();
    
  return (
    <>
      
          <div className="col-md-4">
            <div className="card h-100 shadow-sm">
              <img
                src={process.env.PUBLIC_URL + props.product.image[0]}
                className="card-img-top"
                alt="..."
              />
              <div className="card-body">
                
                <h6 className="card-title">
                 {props.product.name}
                </h6>
                {/* <p className="card-title">{props.product.currencySymbol} {props.product.price}</p> */}
                <div className="d-flex">
                        <p className="align-self-center card-title">{props.product.currencySymbol} {props.product.price}</p><button  onClick={() => dispatch(addToCart(product, 1))} type="button" className="btn btn-default btn-sm" style={{fontSize: "10px",marginTop:"0px",width: "100px"}}><span><i className="fas fa-shopping-cart"></i></span><b> Add to Cart </b></button> 
                    </div>
              </div>
            </div>
         
      </div>
    </>
  );
};
export default Tabdata;
