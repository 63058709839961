import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import Layout from "./components/app";
import Home from "./page/home";
import { Provider } from "react-redux";
import store, { persistor } from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import { fetchProducts } from "./redux/actions/productActions";
import { PersistGate } from "redux-persist/integration/react";
import products from "./data/products.json";
const Root = () => {
  // fetch products from json file
store.dispatch(fetchProducts(products));

  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <Layout>
              <Routes>
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/`}
                  element={<Home />}
                />
              </Routes>
            </Layout>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));
reportWebVitals();
