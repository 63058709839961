import { useState } from "react";
import { filterVariations, formatVariations } from "../helpers/helpers";

export function useVariations(product) {
    console.log(product?.variations?.nodes);
  const [state, setState] = useState({});
  const filteredVariations = filterVariations(
    product?.variations?.nodes,
    state,
  );
  const formatedVariations = formatVariations(
    filteredVariations,
    product?.variations?.nodes,
  );
  return [formatedVariations, state, setState];
}